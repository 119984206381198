.container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  text-decoration: none;
}

.content {
  background-color: #f0f8ff;
  color: #073f70;
  padding: -5px;
  padding-bottom: -5px;
  padding-left: 13px;
  border-radius: 30px 30px 30px 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  size: 13px;
}

/* .sticky-note {
  font-family: 'Montserrat', Helvetica, Arial, serif;
  width: 150px;
  height: 150px;
  padding: 1em;
  font-size: 12px;
  letter-spacing: 2px;
  outline: none;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 40px;

  &::before {
    content: '';
    position: absolute;
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    border-width: 12px 12px 12px 12px;
    border-style: solid;
  }
}

.pin1 {
    width: 47px;
    position: absolute;
    top: -22px;
    left: 0;
  } */

.loan-container {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px auto;
  overflow: hidden;
  grid-template-areas: 'title title' 'sticker1 sticker2';
  margin: 0;
  grid-gap: 10px;
}
@media (max-width: 700px) {
  .loan-container {
    grid-template-columns: 1fr;
    grid-template-areas: 'title' 'sticker1' 'sticker2';
  }
}
.loan-container .notes {
  text-align: center;
  align-self: center;
  font-weight: bold;
  font-size: 60px;
  margin: 0;
}
.note-container {
  justify-self: center;
}
.sticky-note {
  width: 300px;
  min-height: 280px;
  padding: 1em;
  font-size: 15px;
  outline: none;
  position: relative;
  padding-top: 40px;
}
.sticky-note::before {
  content: '';
  position: absolute;
  display: block;
}
.sticky-note::after {
  content: '';
  position: absolute;
  bottom: 0;
  border-width: 12px 12px 12px 12px;
  border-style: solid;
}
.sticky-note.sticky-note-one {
  background-color: #cc843a52;
  grid-area: sticker1;
  box-shadow: 9px 0px 10px -5px rgba(0, 0, 0, 0.42);
  margin-bottom: 40px;
  margin-top: 20px;
}
.sticky-note.sticky-note-one::before {
  background-color: #073f70;
  width: 120px;
  height: 30px;
  left: 50%;
  top: -13px;
  border-radius: 5px;
  transform: translateX(-50%) rotate(360deg);
}
.sticky-note.sticky-note-one::after {
  left: 0;
  border-top-color: #073f70;
  border-right-color: #073f70;
  border-bottom-color: #ffffff;
  border-left-color: #ffffff;
}
.sticky-note.sticky-note-two {
  background-color: #6cd4ff;
  grid-area: sticker2;
  box-shadow: -9px 0px 10px -5px rgba(0, 0, 0, 0.42);
}
.sticky-note.sticky-note-two::before {
  background-color: rgba(250, 227, 227, 0.8);
  height: 70px;
  width: 50px;
  left: 50%;
  top: -30px;
  transform: rotate(-3deg) translateX(-50%);
}
.sticky-note.sticky-note-two::after {
  right: 0;
  border-top-color: #ddc6c3;
  border-left-color: #ddc6c3;
  border-bottom-color: #5d576b;
  border-right-color: #5d576b;
}
.details {
  font-size: 10px;
}


.text-limit {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  transition: all 0.3s ease;
  cursor: pointer;
}

.text-limit:hover {
  -webkit-line-clamp: unset;
  overflow: visible;
  white-space: normal;
}
